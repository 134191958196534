import logo from './csillirazi-logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <h1 className="Logo-brand">
          <span>csill</span>irázi
        </h1>
        <div className="Logo-slogan">
          A Szerethető Ékszer
        </div>
        <div className="Content">
          Hamarosan ...
        </div>
      </header>
    </div>
  );
}

export default App;
